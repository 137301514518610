@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './styles/style.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', serif;
}

.sigcanvas {
  width: 100%;
  min-height: 360px;
  border: 2px solid black;
}

.hidescroll {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hidescroll::-webkit-scrollbar {
  display: none;
}
